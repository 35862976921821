// Load Bootstrap JS
import { Tooltip } from 'bootstrap';
import $ from "jquery";
import slick from 'slick-carousel'

// Load Styles
import '../scss/main.scss';

// App code
$('.team__slider').slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 100000,
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: false,
    responsive: [
        {
            breakpoint: 870,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 570,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl)
})